import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import MasonaryData from './MasonaryData'
import categories from '../data/json/category.json'
import { redirectURLOpen } from '../utils/commonFunctions'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import SwiperCore from 'swiper/core'
import { Autoplay, Navigation, Pagination } from 'swiper/modules'
import firstimages from '../data/json/image.json';

SwiperCore.use([Autoplay, Pagination, Navigation])

const GridPage = () => {

    const [displayedImages, setDisplayedImages] = useState(firstimages);
    const [activeStep, setActiveStep] = useState('all')


    useEffect(() => {
        const storedImages = sessionStorage.getItem('displayedImages');
        const storedStep = sessionStorage.getItem('activeStep');
        if (storedImages && storedStep !== 'all') {
            setDisplayedImages(JSON.parse(storedImages));
            setActiveStep(storedStep);
        } else {
            setDisplayedImages(firstimages);
        }
    }, []);
    const GotoCategory = (item) => {
        let newData;
        if (item.id === 'all') {
            sessionStorage.removeItem('displayedImages');
            newData = firstimages;
        } else {
            newData = firstimages.filter(v => v.category_name.includes(item.id));
        }
        setActiveStep(item.id);
        setDisplayedImages(newData);
        sessionStorage.setItem('displayedImages', JSON.stringify(newData));
        sessionStorage.setItem('activeStep', item.id);
        redirectURLOpen("home");
    };

    // const navigate = useNavigate()

    // const GotoCategory = (item) => {
    //     redirectURLOpen("home") 
    //     console.log('item', item) 
    //     setActiveStep(item.id) 
    //     const newData = item.id === 'all' ? firstimages : firstimages.filter(v => v.category_name.includes(item.id)) 
    //     setDisplayedImages(newData)
    // }
    // console.log('activeStep', activeStep)

    return (
        <>
            <div style={{ boxShadow: '0 2px 4px rgb(0 0 0 / 2%)' }} className='leo-nav px-1 flex items-center bg-[#fff] rounded-[14px]'>
                <a
                    onClick={() => GotoCategory({ id: 'all', CategoryName: 'All' })}
                    className={` w-fit mr-1 inline-flex items-center gap-1 justify-center  whitespace-nowrap text-[13px] cursor-pointer px-4 leading-10 h-10 rounded-[14px] duration-300 king-nav-sub-link ${activeStep === 'all' ? "text-bodybg-light bg-bodybg-dark Catopen" : "text-sidebar-normal bg-bodybg-light"} hover:text-bodybg-light hover:bg-bodybg-dark`}
                >
                    All
                </a>
                <Swiper
                    spaceBetween={10}
                    autoplay={{ delay: 2000, disableOnInteraction: false , pauseOnMouseEnter: true}}
                    loop={true}
                    breakpoints={{
                        1024: {
                            slidesPerView: 10,
                        },
                        600: {
                            slidesPerView: 5,
                        },
                        425: {
                            slidesPerView: 3,
                        },
                        0: {
                            slidesPerView: 2
                        }
                    }}
                    className='flex-1 list-none flex items-center relative  rounded-[14px]   m-[7px]'
                >
                    {
                        categories.map(category => {
                            return (
                                <SwiperSlide key={category}>
                                    <a onClick={() => GotoCategory(category)} className={` w-full inline-flex items-center gap-1 justify-center   whitespace-nowrap text-[13px] cursor-pointer px-4 leading-10 h-10 rounded-[14px] duration-300 king-nav-sub-link ${activeStep === category.id ? "text-bodybg-light bg-bodybg-dark Catopen" : "text-sidebar-normal bg-bodybg-light"} hover:text-bodybg-light hover:bg-bodybg-dark`}>
                                        {/* <img src={category.image} alt="" className="catimage" /> */}
                                        {category.CategoryName}
                                    </a>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </div>
            <div className='mt-5'>
                <MasonaryData displayedImages={displayedImages} setDisplayedImages={setDisplayedImages} />
            </div>
        </>
    )
}

export default GridPage
