import redirectURL from "../data/json/redirectURL.json"

export const redirectURLOpen = (routeName) => {
console.log('routeName',redirectURL[routeName])
const linkS=shuffleArray(redirectURL[routeName])
console.log("shuffleArray",);
    window.open(linkS, "_blank")
}

function shuffleArray(array) {
    const index = Math.floor(Math.random() * array.length);
    return array[index];
}