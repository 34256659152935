import React, { useState, lazy, Suspense } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faChevronLeft, faChevronRight ,faArrowLeftLong} from '@fortawesome/free-solid-svg-icons';
import Footer from '../components/Footer';
import { redirectURLOpen } from '../utils/commonFunctions';

const Download = lazy(() => import('./Download'));

const QuickView = () => {
  const location = useLocation();
  const { objid } = useParams();
  const navigate = useNavigate()
  const { images, imageCount } = location.state || {};
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  if (!images) {
    return <p>No image data found.</p>;
  }
  const openModal = (image, index) => {
    setModalImage({ src: image });
    setIsModalOpen(true);
    setCurrentIndex(index);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage(null);
    redirectURLOpen('download');
  };

  const handleNext = () => {
    const newIndex = (currentIndex + 1) % images.image.length;
    setModalImage({ src: images.image[newIndex] });
    setCurrentIndex(newIndex);
    redirectURLOpen('download');
  };

  const handlePrevious = () => {
    const newIndex = (currentIndex - 1 + images.image.length) % images.image.length;
    setModalImage({ src: images.image[newIndex] });
    setCurrentIndex(newIndex);
    redirectURLOpen('download');
  };

  const handleBack = () =>{
    navigate(-1)
    redirectURLOpen("subHome")
  }
  return (
    <>
      <Link
        to='/'
        onClick={handleBack}
        className='flex w-fit px-3 mt-3 justify-center items-center  bg-gray-800 text-white rounded-full p-2'
      >
        <FontAwesomeIcon icon={faArrowLeftLong} />
      </Link>
      <div className='king-body-in w-full block relative box-border  pt-5 overflow-hidden'>
        <div className='mt-0'>
          <div className='king-main post-page flex mx-auto mb-5 flex-wrap '>
            <div className='king-video-in flex-[8] max-w-full  box-border'>
              <div className='king-video bg-bodybg-dark text-center mx-auto mb-5 rounded-[14px]'>
                <div className='king-gallery owl-carousel owl-loaded owl-drag'>
                  <div className='owl-stage-outer owl-height'>
                    <div className='owl-stage flex flex-wrap mx-auto relative  justify-center'>
                      {images?.image.map((image, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() => redirectURLOpen('subHome')}
                            className='owl-item cursor-pointer'
                            style={{ height: '100%', marginRight: '10px' }}
                          >
                            <div className='king-gallery-img'>
                              <div className='king-gallery-imgs'>
                                <div
                                  onClick={() => openModal(image, index)}
                                  className={`subimage-item ${
                                    imageCount === 1 ? 'w-full' : 'w-full'
                                  } p-2`}
                                >
                                  <img
                                    className='gallery-img king-lazy loaded m-auto rounded-[14px] max-h-[400px] h-full w-full object-cover'
                                    src={image}
                                    alt={`Image ${index + 1}`}
                                  />
                                </div>
                              </div>
                              <a onClick={() => openModal(image, index)} className='aidown'>
                                <button className='absolute w-10 h-10 text-[#fff] top-[10px] left-[10px] rounded-[14px] flex justify-center items-center cursor-pointer border-0'>
                                  <FontAwesomeIcon icon={faDownload} />
                                </button>
                              </a>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isModalOpen && modalImage && (
          <Suspense fallback={<div>Loading...</div>}>
            <div
              style={{ backgroundColor: 'rgb(111 105 132 / 92%)' }}
              className='mfp-bg king-gallery-zoom mfp-ready top-0 left-0 w-full h-full z-[22] overflow-hidden fixed'
            ></div>
            <Download
              subimage={modalImage}
              onClose={closeModal}
              onNext={handleNext}
              currentIndex={currentIndex}
              onPrevious={handlePrevious}
            />
          </Suspense>
        )}
      </div>
    </>
  );
};

export default QuickView;
