import React from 'react';

const Terms = () => {
  return (
    <div className=' w-full '>
      <div className='border border-transparent  rounded-[10px]'>
        <div className='my-2 font-semibold text-[24px] '>
          <h1 className='policytitle '>Terms & Conditions</h1>
        </div>
        <div className='text-[16px]'>
          <p className='mb-2 text-justify'>
            1.1 The Website allows users to upload, share, and view adult content depicting sexually
            explicit images. The Website also contains other content and material uploaded or posted
            by users, including text, messages, images, photos, and files. By accessing the Website,
            you may see graphic depictions, nudity, adult language, and descriptions of explicit
            sexual activity, including heterosexual, bisexual, homosexual, and transsexual
            situations of a sexual nature. Access and registration to the Website is free. The only
            time you must pay is if you purchase access to the premium area.
          </p>
        </div>
        <div className='text-[16px]'>
          <p className='mb-2 text-justify'>
            1.2 This agreement applies to all users of the Website, whether you are a 'visitor' or a
            'registered user.', By clicking on the ',I Agree', button on the warning page, checking
            the appropriate box during registration, purchasing access to premium content, or
            accessing any part of the Website, you agree to this agreement. If you do not want to
            agree to this agreement, you must leave the Website. If you breach any part of this
            agreement, the Company may revoke your license to access the Website, block your access,
            and cancel your account (if you have one).
          </p>
        </div>
        <div className='text-[16px]'>
          <p className='mb-2 text-justify'>
            1.3 The Company is not liable for anything that you post or say while you are on the
            Website. The Company does not monitor the content of the Website, but if the Company
            does see, or someone tells the Company that you have posted, something that the Company
            finds inappropriate, the Company will remove it and may take appropriate action against
            you. If you post content that belongs to someone else and they get annoyed (or even call
            in their lawyers), the Company is not in the firing line. You have to take
            responsibility for what you post.
          </p>
        </div>
        <div className='text-[16px]'>
          <p className='mb-2 text-justify'>
            1.4 By accessing the Website, you state that the following facts are accurate: You are
            at least 18-years old, have reached the age of majority where you live, and you have the
            legal capacity to enter this agreement; All information you provided to the Company is
            accurate, and you will promptly update this information when necessary to make sure that
            it remains accurate; You are aware of the adult nature of the content available on the
            Website, and you are not offended by visual images, verbal descriptions, and audio
            sounds of a sexual nature, which may include graphic visual depictions and descriptions
            of nudity and sexual activity;
          </p>
        </div>
        <div className='text-[16px]'>
          <p className='mb-2 text-justify'>
            1.5 You are familiar with your community's laws affecting your right to access
            adult-oriented materials, including sexually explicit material depicting bondage, S/M,
            and other fetish activities; You have the legal right to access adult-oriented
            materials, including sexually explicit material depicting bondage, S/M, and other fetish
            activities, and the Company has the legal right to transmit them to you; You are
            voluntarily requesting adult-oriented materials for your own private enjoyment; You are
            not accessing the Website from a place, country, or location in which doing so would, or
            could be considered a violation of law;
          </p>
        </div>
        <div className='text-[16px]'>
          <p className='mb-2 text-justify'>
            1.6 You will not share these materials with a minor or otherwise make them available to
            a minor; and By accessing the Website, you will have released and discharged the
            providers, owners, and creators of the Website from all liability that might arise.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Terms;
