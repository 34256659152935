import './App.css';
import Layout from './components/Layout';
import { Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import QuickView from './pages/QuickView';
import Download from './pages/Download';
import SearchComponent from './pages/SearchComponent';
import HotPage from './pages/HotPage';
import Popular from './pages/Popular';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';

function App() {
  
  return (
    <>
      <Layout>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/quickview' element={<QuickView />} />
          <Route path='/download' element={<Download/>}/>
          <Route path='/searchpage' element={<SearchComponent/>}/>
          <Route path='/hot' element={<HotPage/>}/>
          <Route path='/popular' element={<Popular/>} />
          <Route path='/policy' element={<PrivacyPolicy/>}/>
          <Route path='/terms' element={<Terms/>}/>
        </Routes>
      </Layout>
    </>
  );
}

export default App;
