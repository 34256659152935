import React from 'react'
import { Link } from 'react-router-dom'


const Footer = () => {

    const policyTop = () => {
        window.scrollTo( {top : 0})
    }
    const termsTop = () => {
        window.scrollTo( {top : 0})
    }
    return (
        <div>
            <div className='text-[10px]'>
                2024
                <Link className='text-[#1d1d1f] hover:text-btnhover mx-1'>Kringle Kin</Link>
                | All rights reserved -
                <Link to='/policy' onClick={policyTop}  className='text-[#1d1d1f] hover:text-btnhover mx-1'>Privacy Policy</Link>
                | <Link to='/terms' onClick={termsTop} className='text-[#1d1d1f] hover:text-btnhover mx-1'>Terms & Conditions</Link>
            </div>
        </div>
    )
}

export default Footer
