import React from 'react'
import GridPage from '../pages/GridPage'

const HomePage = () => {
  return (
      <div className='w-full block relative  pt-5 overflow-hidden'>
        <GridPage />
      </div>
  )
}

export default HomePage
