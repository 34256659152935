import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className=' w-full '>
      <div className='border border-transparent  rounded-[10px]'>
        <div className='my-2 font-semibold text-[24px] '>
          <h1 className='policytitle '>Privacy Policy </h1>
        </div>
        <div className='text-[16    px]'>
          <p className='mb-2 text-justify'>
            This privacy notice will inform you as to how we look after your personal data when you
            visit our website (regardless of where you visit it from) and tell you about your
            privacy rights and how the law protects you. This privacy notice is provided in a
            layered format so you can click through to the specific areas set out below. Please also
            use the Glossary to understand the meaning of some of the terms used in this privacy
            notice. Third-Party Links This website may include links to third-party websites,
            plug-ins, and applications. Clicking on those links or enabling those connections may
            allow third parties to collect or share data about you. We do not control these
            third-party websites and are not responsible for their privacy statements. When you
            leave our website, we encourage you to read the privacy notice of every website you
            visit.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
