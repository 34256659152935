import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faSearch } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import bgimage1 from '../assets/images/bgimage1.png'
import bgimage2 from '../assets/images/bgimage2.jpg'
import bgimage3 from '../assets/images/bgimage3.png'
import bgimage4 from '../assets/images/bgimage4.jpg'
import LogoLight from '../assets/images/logoLight.png'
import LogoDark from '../assets/images/logoDark.png'
import GridPage from './GridPage';
import categories from '../data/json/category.json'
import { redirectURLOpen } from '../utils/commonFunctions';

const SearchComponent = () => {
    const [randomCategories, setRandomCategories] = useState([]);
    

    useEffect(() => {
        const shuffledCategories = shuffleArray([...categories]);
        setRandomCategories(shuffledCategories);
    }, []);

    function shuffleArray(array) {
        const index = array.sort(() => 0.5 - Math.random())
        return index.slice(0, 5)
    }



    const backgroundStyle = {
        backgroundImage: `linear-gradient(0deg, rgba(246, 247, 249, 0.92), rgba(246, 247, 249, 0.52)), 
                          linear-gradient(180.08deg, rgba(246, 247, 249, 0.12) 33.15%, 
                                           rgba(246, 247, 249, 0.12) 38.05%, 
                                           rgba(246, 247, 249, 0.14) 42.93%, 
                                           rgba(246, 247, 249, 0.16) 47.77%, 
                                           rgba(246, 247, 249, 0.19) 52.57%, 
                                           rgba(246, 247, 249, 0.22) 57.31%, 
                                           rgba(246, 247, 249, 0.27) 61.99%, 
                                           rgba(246, 247, 249, 0.32) 66.6%, 
                                           rgba(246, 247, 249, 0.38) 71.14%, 
                                           rgba(246, 247, 249, 0.45) 75.58%, 
                                           rgba(246, 247, 249, 0.52) 79.93%, 
                                           rgba(246, 247, 249, 0.61) 84.17%, 
                                           rgba(246, 247, 249, 0.69) 88.3%, 
                                           rgba(246, 247, 249, 0.79) 92.31%, 
                                           rgba(246, 247, 249, 0.89) 96.19%, 
                                           #ffffff 100%)`
    };
    return (
        <>
            <div className='relative flex min-h-[350px]'>
                <div style={backgroundStyle} className='king-body-search flex flex-col text-center items-center text-sidebar-normal absolute inset-0 z-[1] '>
                    <div className=' king-logo pt-2 LogoM:max-w-full max-w-[60%]'>
                        <Link to='/' className='text-[22px]  ' onClick={() => redirectURLOpen("home")}>
                            <img src={LogoLight} className='max-h-[80px] king-logoD  ' alt="" />
                            <img src={LogoDark} className=' max-h-[80px] king-logoL hidden ' alt="" />
                        </Link>
                    </div>
                    {/* <h3 className='text-[20px] leading-[28px]'>Create any image from your imagination.</h3> */}
                    <div className="king-search fixed z-[19] px-5 pt-[60px] top-[-600px] inset-x-0 bg-[#fff] opacity-0 ">
                        <div className="king-search-in lg:max-w-[50%] max-w-full mt-0 mx-auto flex flex-col " style={{ display: "-webkit-flex" }}>
                            <form action="" className='flex relative '>
                                <input style={{ boxShadow: ' 0 10px 40px #0000001a' }} type="text" className='king-search-field border-2 border-solid outline-none text-[#464646] bg-[#ffffffbf] backdrop-blur-[5px] rounded-[14px] LogoM:flex-1 flex-auto max-LogoM:w-full  text-[22px] leading-10 py-2 px-[14px] ' placeholder='Search' autoComplete='off' />
                                <button type='submit' onClick={() => redirectURLOpen("home")} className='w-[60px] cursor-pointer bg-inherit border-0 text-[27px] text-[#ced0d9] absolute right-[5px] top-[10px]'>
                                    <FontAwesomeIcon icon={faSearch} className='font-black' />
                                </button>
                            </form>
                            <div className='search-disc flex w-full gap-[6px] items-center my-5 mx-[10px] justify-center flex-wrap'>
                                <h3 className='text-[20px] leading-[28px] '>Discover</h3>
                                {
                                    randomCategories.map(category => {
                                        return (
                                            <Link key={category} to='' onClick={() => redirectURLOpen("home")} className='text-sm py-[4px] px-3 transition-all border border-solid border-[#646373a1] hover:border-btnhover hover:text-btnhover rounded-[50px] text-sidebar-normal'>
                                                {category.CategoryName}
                                            </Link>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="king-featureds text-center block p-0 w-full">
                    <div style={{ gridTemplateColumns: "25% 25% 25% 25%" }} className='md:grid flex flex-col text-[22px] gap-0 overflow-hidden auto-rows-[220px]'>
                        <div className='col-start-1 col-end-auto row-start-1 row-end-3 bg-[#fff] relative md:w-full w-auto max-md:h-[120px] block overflow-hidden'>
                            <Link to='' className='relative h-full block overflow-hidden'>
                                <div className="featured-post bg-[#c2c6cb] absolute inset-0 h-full ">
                                    <div className='king-box-bg loaded absolute inset-0 opacity-0 bg-cover bg-center' style={{ backgroundImage: `url(${bgimage1})` }}></div>
                                </div>
                            </Link>
                            <div className="featured-content absolute left-[15px] bottom-0 right-[10px] text-left py-2 px-4 text-[#ffffff9e] bg-transparent z-[4] opacity-0 invisible transition-opacity duration-[400]">
                                <Link style={{ textShadow: '1px 2px 2px rgba(0, 0, 0, .2)' }} className='leading-[1.4em] block font-medium text-[#fff] m-0 text-[18px] '>
                                    Old yellow school bus
                                </Link>
                            </div>
                        </div>
                        <div className='col-start-2 col-end-auto row-start-1 row-end-3 bg-[#fff] relative md:w-full w-auto max-md:h-[120px] block overflow-hidden'>
                            <Link to='' className='relative h-full block overflow-hidden'>
                                <div className="featured-post bg-[#c2c6cb] absolute inset-0 h-full ">
                                    <div className='king-box-bg loaded absolute inset-0 opacity-0 bg-cover bg-center' style={{ backgroundImage: `url(${bgimage2})` }}></div>
                                </div>
                            </Link>
                            <div className="featured-content absolute left-[15px] bottom-0 right-[10px] text-left py-2 px-4 text-[#ffffff9e] bg-transparent z-[4] opacity-0 invisible transition-opacity duration-[400]">
                                <Link style={{ textShadow: '1px 2px 2px rgba(0, 0, 0, .2)' }} className='leading-[1.4em] block font-medium text-[#fff] m-0 text-[18px] '>
                                    Old yellow school bus
                                </Link>
                            </div>
                        </div>
                        <div className='col-start-3 col-end-auto row-start-1 row-end-3 text-[28px] bg-[#fff] relative md:w-full w-auto max-md:h-[120px] block overflow-hidden'>
                            <Link to='' className='relative h-full block overflow-hidden'>
                                <div className="featured-post bg-[#c2c6cb] absolute inset-0 h-full ">
                                    <div className='king-box-bg loaded absolute inset-0 opacity-0 bg-cover bg-center' style={{ backgroundImage: `url(${bgimage3})` }}></div>
                                </div>
                            </Link>
                            <div className="featured-content absolute left-[15px] bottom-0 right-[10px] text-left py-2 px-4 text-[#ffffff9e] bg-transparent z-[4] opacity-0 invisible transition-opacity duration-[400]">
                                <Link style={{ textShadow: '1px 2px 2px rgba(0, 0, 0, .2)' }} className='leading-[1.4em] block font-medium text-[#fff] m-0 text-[18px] '>
                                    Old yellow school bus
                                </Link>
                            </div>
                        </div>
                        <div className='col-start-4 col-end-auto row-start-1 row-end-3 text-[28px] bg-[#fff] relative md:w-full w-auto max-md:h-[120px] block overflow-hidden'>
                            <Link to='' className='relative h-full block overflow-hidden'>
                                <div className="featured-post bg-[#c2c6cb] absolute inset-0 h-full ">
                                    <div className='king-box-bg loaded absolute inset-0 opacity-0 bg-cover bg-center' style={{ backgroundImage: `url(${bgimage4})` }}></div>
                                </div>
                            </Link>
                            <div className="featured-content absolute left-[15px] bottom-0 right-[10px] text-left py-2 px-4 text-[#ffffff9e] bg-transparent z-[4] opacity-0 invisible transition-opacity duration-[400]">
                                <Link style={{ textShadow: '1px 2px 2px rgba(0, 0, 0, .2)' }} className='leading-[1.4em] block font-medium text-[#fff] m-0 text-[18px] '>
                                    Old yellow school bus
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full block relative pt-5 overflow-hidden'>
                <GridPage />
            </div>
        </>
    )
}

export default SearchComponent
