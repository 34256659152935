import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faDownload } from '@fortawesome/free-solid-svg-icons';
import { redirectURLOpen } from '../utils/commonFunctions';

const Download = ({ subimage, onClose, onNext, onPrevious }) => {

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    if (!subimage) {
        return <p>No image data found.</p>;
    }

    return (
        <div style={{ overflow: 'hidden', WebkitBackfaceVisibility: 'hidden' }} className='mfp-wrap mfp-gallery mfp-auto-cursor king-gallery-zoom mfp-ready top-0 left-0 w-full h-full z-[38] fixed outline-none'>
            <div className='mfp-container flex justify-center mfp-image-holder mfp-s-ready text-center absolute w-full h-full left-0 top-0 bottom-0 px-2 box-border overflow-auto'>
                <div className='mfp-content'>
                    <div style={{ visibility: 'visible' }} className='mfp-figure pb-5'>
                        <div className='mfp-close fixed z-[14] right-[30px] top-[10px] w-10 h-10 leading-[36px] border-0 bg-inherit text-center text-[34px] rounded-[218px] text-[#fff] cursor-pointer outline-none' onClick={onClose}></div>
                        <figure className='block'>
                            <img
                                style={{ maxHeight: 'auto' }}
                                className='mfp-img max-h-screen max-w-[80vw] rounded-[14px] object-contain'
                                src={subimage.src}
                                alt={subimage.title}
                            />
                        </figure>
                    </div>
                </div>
                <div className='fixed download justify-between flex bottom-[5px] '>
                    <div className='mfp-arrow mfp-arrow-left mfp-prevent-close hover:text-[#000]' onClick={onPrevious}></div>
                    <a
                        href={subimage.src}
                        download={subimage.title || 'image'}
                        className='relative bottom-[5px] h-fit bg-[#fff] text-[#000] py-2 px-10 rounded-full shadow-lg hover:bg-[#000] hover:text-[#fff] cursor-pointer flex gap-2 items-center'
                        title='Download Image'
                        onClick={()=>redirectURLOpen("download")}
                    >
                        <FontAwesomeIcon icon={faDownload} />
                        Download
                    </a>
                    <div  className='mfp-arrow mfp-arrow-right mfp-prevent-close hover:text-[#000]' onClick={onNext}></div>
                </div>
                <button
                    className='mfp-close fixed z-[14] right-[30px] top-[10px] w-8 h-8 leading-[36px] border-0  text-center text-[18px] rounded-[218px]  bg-[#fff] cursor-pointer outline-none '
                    onClick={onClose}
                >
                    <FontAwesomeIcon icon={faTimes} />
                </button>
            </div>
        </div>
    );
};

export default Download;
