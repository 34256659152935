import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faFireFlameSimple, faHashtag, faUsers, faUser, faSun, faMoon} from '@fortawesome/free-solid-svg-icons';
import { redirectURLOpen } from '../utils/commonFunctions';


const Sidebar = ({ issideMenuOpen }) => {

    const [isMenuopen, setIsMenuOpen] = useState(false)
    const [isDarkMode, setIsDarkMode] = useState(true)
    const location = useLocation();

    const isActive = (path) => location.pathname === path;


    const ToggleMegamenu = () => {
        setIsMenuOpen(!isMenuopen)
        redirectURLOpen("home")
    }

    const toggleDarkMode = () => {
        setIsDarkMode(!isDarkMode);
        redirectURLOpen("home")
    };

    useEffect(() => {
        if (isDarkMode) {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }
    }, [isDarkMode]);

    useEffect(() => {
        setIsMenuOpen(issideMenuOpen);
    }, [issideMenuOpen]);
    const closeMenu = () => {
        setIsMenuOpen(false);
        redirectURLOpen("home")
    }


    return (
       
        <div className={`leftmenu ${isMenuopen ? 'w-[280px] ' : ' md:w-20 left-[-600px] '} ${issideMenuOpen ? 'left-0 ' : 'left-[-600px]'} bg-[#fff]  fixed top-0  py-[10px] md:left-0  min-h-[400px] bottom-0 z-[13] overflow-hidden overflow-x-hidden overflow-y-auto flex  flex-col items-start`}>
        <button onClick={ToggleMegamenu} className={`${isMenuopen ? 'king-left-open' : 'king-left-close'}  hidden py-[1px] px-[6px] bg-inherit border-0 md:block w-[34px] h-[34px] relative cursor-pointer ml-5`}></button>
        <div className='m-auto min-w-[250px] w-full litmenu'>
            <button onClick={closeMenu} className='absolute right-14 top-2 block md:hidden  king-left-open'></button>
                    <ul className='text-sm list-none font-light list '>
                        <li>
                            <Link to='/' onClick={()=>redirectURLOpen("home")} className={`king-nav-main-link  ${isActive('/') ? ' selected ' : ''} font-medium text-sm m-1 block pr-[10px] pl-[82px] leading-10 mb-2 text-left duration-200 relative text-sidebar-normal hover:text-bodybg-dark`}>
                                <div className='w-[50px] h-10 absolute text-sm left-2 leading-10 rounded-[8px]  text-center top-0'>
                                    <FontAwesomeIcon icon={faHome} className=' ' />
                                </div>
                                Home
                            </Link>
                        </li>
                        <li>
                            <Link to='/hot' onClick={()=>redirectURLOpen("home")} className={`king-nav-main-link ${isActive('/hot') ? ' selected ' : ''}  font-medium text-sm m-1 block pr-[10px] pl-[82px] leading-10 mb-2 text-left duration-200 relative text-sidebar-normal hover:text-bodybg-dark`}>
                                <div className='w-[50px] h-10 absolute text-sm left-2 leading-10 rounded-[8px]  text-center top-0'>
                                    <FontAwesomeIcon icon={faFireFlameSimple} className=' ' />
                                </div>
                                Hot!
                            </Link>
                        </li>
                        <li>
                            <Link to='/popular' onClick={()=>redirectURLOpen("home")} className={`king-nav-main-link ${isActive('/popular') ? ' selected ' : ''}  font-medium text-sm m-1 block pr-[10px] pl-[82px] leading-10 mb-2 text-left duration-200 relative text-sidebar-normal hover:text-bodybg-dark`}>
                                <div className='w-[50px] h-10 absolute text-sm left-2 leading-10 rounded-[8px]  text-center top-0'>
                                    <FontAwesomeIcon icon={faHashtag} className=' ' />
                                </div>
                                Popular
                            </Link>
                        </li>
                        <li>
                            <Link to='' onClick={()=>redirectURLOpen("home")} className={`king-nav-main-link ${isActive('/topusers') ? ' selected ' : ''}  font-medium text-sm m-1 block pr-[10px] pl-[82px] leading-10 mb-2 text-left duration-200 relative text-sidebar-normal hover:text-bodybg-dark`}>
                                <div className='w-[50px] h-10 absolute text-sm left-2 leading-10 rounded-[8px]  text-center top-0'>
                                    <FontAwesomeIcon icon={faUsers} className=' ' />
                                </div>
                                Top Models
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="userleft mb-[10px] ml-5">
                    <div title='Login' onClick={()=>redirectURLOpen("home")} className='bg-bodybg-light reglink text-bodybg-dark hover:text-btnhover h-10 w-10 block leading-10 text-center cursor-pointer rounded-[34px] transition-all duration-200 ease-out'>
                        <FontAwesomeIcon icon={faUser} />
                    </div>
                </div>
                <label  onClick={toggleDarkMode} htmlFor='king-lnight' className='king-nightb w-[42px] flex flex-col items-center text-sidebar-normal h-[85px] py-[13px] px-1 rounded-[32px] bg-bodybg-light justify-between relative cursor-pointer ml-5'>
                    <FontAwesomeIcon icon={faSun} className='z-[2] text-[15px] relative light' />
                    <FontAwesomeIcon icon={faMoon} className='z-[2] text-[15px] relative dark' />
                </label>
            </div>
    )
}

export default Sidebar
