import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faSearch } from '@fortawesome/free-solid-svg-icons';
import Masonry from 'react-layout-masonry';
import Modal from 'react-modal';
import categories from '../data/json/category.json';
import { redirectURLOpen } from '../utils/commonFunctions';

Modal.setAppElement('#root');

const MasonaryData = ({displayedImages,setDisplayedImages}) => {
    const navigate = useNavigate();
    const [fooData, setFooData] = useState(() => JSON.parse(sessionStorage.getItem('fooData')) || displayedImages.slice(0, 20));
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const [firstTime, setFirstTime] = useState(true)
    useEffect(() => {
        const savedScrollPosition = sessionStorage.getItem('scrollPosition');
        if (savedScrollPosition) {
            window.scrollTo(0, parseInt(savedScrollPosition));
        }
    }, []);
    const handleQuickView = (img) => {
        sessionStorage.setItem('scrollPosition', window.scrollY);
        navigate('/quickview', { state: { images: img, imageCount: displayedImages.length } });
        window.scrollTo({top:0})
    };
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && hasMore && !loading) {
                    setLoading(true);
                    setTimeout(() => {
                        const nextImages = displayedImages.slice(fooData.length, fooData.length + 20);
                        if (nextImages.length === 0) {
                            setHasMore(false);
                        } else {
                            const updatedFooData = [...fooData, ...nextImages];
                            setFooData(updatedFooData);
                            sessionStorage.setItem('fooData', JSON.stringify(updatedFooData));
                        }
                        setLoading(false);
                    }, 1000);
                }
            },
            { threshold: 1.0 }
        );
        const target = document.querySelector('#loadMoreTrigger');
        if (target) {
            observer.observe(target);
        }
        return () => {
            if (target) {
                observer.unobserve(target);
            }
        };
    }, [fooData, hasMore, loading, displayedImages]);
    useEffect(() => {
        if (displayedImages.length > 20 && fooData.length <= 20) {
            setHasMore(true);
        }
    }, [displayedImages, fooData.length]);
    useEffect(() => {
        if(firstTime){setFirstTime(false)}
        if(!firstTime){
            setFooData(displayedImages.slice(0, 20));
        }
    }, [displayedImages]);

    return (
        <div className="king-main full-page flex  mx-[-1%]">
            <div style={{ flex: '6' }} className="king-main-in min-h-[500px] px-[1%]">
                <div className="king-part-q-list king-inner flex relative mb-5">
                    <div className="block mx-[-0.5%] LogoM:pb-20 pb-5 masonry-container">
                        <Masonry columns={{ 1500: 5, 768: 5, 640: 3, 450: 1 }} gap={10} className='pb-8'>
                            {fooData.map((img, index) => (
                                <div key={index} onClick={() => handleQuickView(img)} className='box king-q-list-item king-class-image inline-block w-full px-[0.5%] pb-[1%] text-sm relative'>
                                    <div className="king-post-upbtn absolute top-[15px] left-[25px] z-[2] flex items-center flex-col invisible">
                                        <Link to='/quickview' onClick={()=>redirectURLOpen("home")} style={{ boxShadow: '0 1px 1px rgb(0 0 0 / 10%)' }} title='Download' className='bg-[#0000005c] text-[#fff] hover:text-btnhover w-[30px] h-[30px] mb-[5px] z-[2] rounded-[33px] leading-[30px] text-center'>
                                            <FontAwesomeIcon icon={faDownload} className='font-black' />
                                        </Link>
                                    </div>
                                    <a title='Quick View' className='mgbutton bg-[#0000005c] text-[#fff] hover:text-btnhover w-10 h-10 mb-[5px] z-[12] rounded-[33px] leading-10 text-center absolute left-[50%] top-[50%] invisible opacity-0' onClick={()=>redirectURLOpen("home")}>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </a>
                                    <div className='king-q-item-main overflow-hidden bg-[#ffffff] rounded-[14px] relative flex flex-col'>
                                        <Link style={{ WebkitFlex: '1' }} onClick={()=>redirectURLOpen("home")} className='item-a block overflow-hidden rounded-[14px] relative z-[1]'>
                                            <span className='post-featured-img img-loaded block bg-[#e1e0e5] relative max-h-[900px]'>
                                                <img src={img?.image[0]} className='item-img king-lazy loaded opacity-0' />
                                            </span>
                                        </Link>
                                        <div className='king-post-content flex flex-col flex-1 pt-[10px] px-[10px] absolute z-[2] bottom-0 inset-x-0 opacity-0 invisible'>
                                            <div className='king-q-item-title text-[17px] leading-[24px] break-words text-[#383838] block'>
                                                <div className='king-title-up'>
                                                    <Link onClick={()=>redirectURLOpen("home")} className='king-category-link text-[#fff9] hover:text-[#fff] text-xs pr-[10px] font-medium leading-[24px] inline-flex flex-wrap'>
                                                    {categories.filter(w => img?.category_name.includes(w.id)).map((v, index, arr) => (<div className='text-nowrap overflow-hidden pr-1' key={v.id}>{v.CategoryName}{index < arr.length - 1 ? ', ' : ''}</div>))}                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Masonry>
                        {loading && (
                            <div className="switch-loader text-center mt-4">
                                <div className='loader '></div>
                            </div>
                        )}
                        {hasMore && !loading && (
                            <div id="loadMoreTrigger" className=" text-center bg-[#1d1d1f] cursor-pointer absolute inset-x-0 bottom-0 mx-auto h-[42px] leading-[42px] w-[280px] text-sm rounded-[14px] ">
                                <Link className='text-[#fff]'>Load More</Link>
                            </div>
                        )}
                        {!hasMore && !loading && (
                            <div className='load-nomore shadow-inherit bg-[#ffft] text-center text-[#73859f] absolute inset-x-0 bottom-0 mx-auto h-[42px] leading-[42px] w-[280px] text-sm rounded-[14px] '>
                                End of the page.
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MasonaryData;
