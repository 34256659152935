import React , {useState} from 'react'
import Header from './Header'
import Sidebar from './Sidebar'
import Footer from './Footer'
import { redirectURLOpen } from '../utils/commonFunctions'

const Layout = ({ children }) => {

    const [issideMenuOpen, setIsSideMenuOpen] = useState(false);

    const ToggleSidemenu = () => {
        setIsSideMenuOpen(!issideMenuOpen);
        redirectURLOpen("home")
    };
    return (
        <div className={`flex flex-col min-h-screen md:pl-20 pl-0 ${issideMenuOpen ? 'active' : ''}`}>
            <header className='king-headerf scrolled-up z-[18] w-full top-[-66px] block'>
                <Header ToggleSidemenu={ToggleSidemenu} issideMenuOpen={issideMenuOpen}/>
                <Sidebar  issideMenuOpen={issideMenuOpen}/>
            </header>
            <main className='flex-grow px-[2%] '>
                {children}
            </main>
            <div className='px-[2%]'>
                <footer className='king-footer py-2 w-full mt-[30px] mb-[10px] block relative clear-both text-[#758399] text-center bg-[#fff] rounded-[14px]'>
                    <Footer />
                </footer>
            </div>
        </div>
    )
}

export default Layout
