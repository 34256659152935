import React from 'react'
import { Link } from 'react-router-dom'
import LogoLight from '../assets/images/logoLight.png'
import LogoDark from '../assets/images/logoDark.png'
// import LogoMedium from '../assets/images/logoM.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFireFlameSimple,faUsers,faHashtag, faSearch } from '@fortawesome/free-solid-svg-icons';
import { redirectURLOpen } from '../utils/commonFunctions'


const Header = ({ ToggleSidemenu , issideMenuOpen}) => {

   

    return (
        <div className='bg-light king-header px-[2%] relative flex flex-nowrap justify-between items-center backdrop-blur-md h-[66px] z-[12] shadow-headershadow'>
            <div className='header-left flex items-center flex-1 '>
                <button onClick={ToggleSidemenu} className={`${issideMenuOpen ? 'toggle' : ' toggle'} md:hidden block  text-[22px] w-10 h-10 leading-10 cursor-pointer bg-inherit text-center relative rounded-[14px] p-0 transition-all duration-500 ease-in-out`}></button>
                <div className='max-h-[66px] king-logo '>
                    <Link to='/' className='text-[22px] ' onClick={()=>redirectURLOpen("home")}>
                        <img src={LogoLight} className='max-h-[60px] max-w-[100%]  sm:max-h-[60px] sm:max-w-[50%] md:pl-0 sm:pl-2 pl-5 king-logol  ' alt="" />
                        <img src={LogoDark} className=' hidden max-h-[60px] max-w-[100%]  sm:max-h-[60px] sm:max-w-[50%] king-logon md:pl-0 sm:pl-2 pl-5' alt="" />
                        {/* <img src={LogoMedium} className='LogoM:hidden block max-h-[60px] king-logom' alt="" /> */}
                    </Link>
                </div>
            </div>
            <div className="header-middle lg:flex hidden items-center grow-0 shrink-0 basis-auto">
                <ul className='flex list-none gap-5 king-nav-head-list'>
                    <li>
                        <Link to='/hot' onClick={()=>redirectURLOpen("home")} className='king-nav-head-link text-[13px] px-[2px] text-bodybg-dark rounded-[14px] relative h-[66px] leading-[66px] inline-block'>
                            <FontAwesomeIcon icon={faFireFlameSimple} className='mr-2' />
                            Hot!
                        </Link>
                    </li>
                    <li>
                        <Link to='/popular' onClick={()=>redirectURLOpen("home")} className='king-nav-head-link text-[13px] px-[2px] text-bodybg-dark rounded-[14px] relative h-[66px] leading-[66px] inline-block'>
                            <FontAwesomeIcon icon={faHashtag} className='mr-2' />
                            Popular
                        </Link>
                    </li>
                    <li>
                        <Link to='' onClick={()=>redirectURLOpen("home")} className='king-nav-head-link text-[13px] px-[2px] text-bodybg-dark rounded-[14px] relative h-[66px] leading-[66px] inline-block'>
                            <FontAwesomeIcon icon={faUsers} className='mr-2' />
                            Top Models
                        </Link>
                    </li>
                    
                </ul>
            </div>
            <div className="header-right text-center flex-1">
                <ul className='flex justify-start h-[60px] items-center flex-row-reverse'>
                    <li className='list-none relative ml-[9px]'>
                        <Link to='/searchPage' onClick={()=>{redirectURLOpen("home");  window.scrollTo( {top : 0}) }} className='inline-block cursor-pointer text-center text-bodybg-dark hover:text-btnhover hover:border-btnhover rounded-[14px] w-10 h-10 leading-10 bg-bodybg-light'>
                            <FontAwesomeIcon icon={faSearch} className='h-[18px] w-[18px] mt-2.5' />
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Header
